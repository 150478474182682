var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"seeAlso"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"sectionTitle bef"},[_vm._v(" "+_vm._s(_vm.$t("seeAlsoTitle"))+" "),_c('span',[_vm._v(_vm._s(_vm.morfTitle))])]),_c('div',{staticClass:"variants"},_vm._l((_vm.tabList),function(item,idx){return _c('div',{key:idx,staticClass:"item"},[(item.type === 'hotels')?_c('router-link',{attrs:{"to":{
            name: 'ContryHotels',
            params: {
              countryName: _vm.$route.params.countryName,
              locale: _vm.currentLangForLink
            }
          }}},[_vm._v(_vm._s(item.tab_name || item.name)+" "),_c('i',{staticClass:"ic-arrow"})]):(item.type === 'tours')?_c('router-link',{attrs:{"to":{
            name: 'CountryTours',
            params: {
              countryName: _vm.$route.params.countryName,
              locale: _vm.currentLangForLink
            }
          }}},[_vm._v(_vm._s(item.tab_name || item.name)+" "),_c('i',{staticClass:"ic-arrow"})]):(item.type === 'info')?_c('router-link',{attrs:{"to":{
            name: 'CountryInfos',
            params: {
              countryName: _vm.$route.params.countryName,
              infoPageSlug: item.slug,
              locale: _vm.currentLangForLink
            }
          }}},[_vm._v(_vm._s(item.tab_name || item.name)+" "),_c('i',{staticClass:"ic-arrow"})]):_c('router-link',{attrs:{"to":{
            name: 'Contry',
            params: {
              countryName: _vm.$route.params.countryName,
              locale: _vm.currentLangForLink
            }
          }}},[_vm._v(" "+_vm._s(item.tab_name || item.name)+" "),_c('i',{staticClass:"ic-arrow"})])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }