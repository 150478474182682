<template>
  <aside class="aside">
    <template v-if="getSize != 'mobile'">
      <p class="title">{{ title ? title : "Інформація:" }}</p>
      <ul v-if="ankerList">
        <li
          v-for="(item, idx) in ankerList"
          :key="idx"

          @click="currentH2Idx = idx"
        >
          <a :href="item.link" :class="{ active: currentH2Idx === idx }" @click="onItemClick(item.title)">{{
            item.title
          }}</a>
        </li>
      </ul>
    </template>
    <template v-else>
      <accordion class="asideMob">
        <accordion-item>
          <template slot="accordion-trigger" id="c1"
            ><p class="title">{{ title ? title : "Інформація:" }}</p>
            <i class="ic-dropdown"></i>
          </template>
          <template slot="accordion-content">
            <li
              v-for="(item, idx) in ankerList"
              :key="idx"
              @click="currentH2Idx = idx"
            >
              <a :href="item.link" :class="{ active: currentH2Idx === idx }" @click="onItemClick(item.title)">{{
                item.title
              }}</a>
            </li>
          </template>
        </accordion-item>
      </accordion>
    </template>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import AccordionItem from "../common/Accordion-item.vue";
import Accordion from "../common/Accordion.vue";
export default {
 components: { Accordion, AccordionItem },
  data() {
    return {
      currentH2Idx: 0
    };
  },
  computed: {
    ...mapGetters(["getSize"])
  },
  props: {
    ankerList: {
      type: [Array, Object]
    },
    title: {
      type: String
    }
  },
  methods: {
    onItemClick(e) {
      this.$emit("click", e);
      setTimeout(() => {
        let el = document.getElementById(e);
        if (el) {
          window.scrollTo({
            top: el.offsetTop - 120,
            behavior: "smooth"
          });
        }
      }, 200);
    }
  }
};
</script>
<style lang="sass">
.asideMob
    li, a
        color: #777 !important
    li::before
        display: none
.asideMob .accordion__content
    margin-bottom: 0px
    margin-top: 35px
    ul
        padding: 0px
.asideMob .accordion__trigger.accordion__trigger_active
    p
        color: $c-btn
</style>

<style lang="scss" scoped></style>
