<template>
    <div class="rental airplane darkBg" v-if="!isUiLocked">
        <div class="container textContainer">
            <the-main-text
                :title="countryInfo.name"
                :descr="countryInfo.description"
            ></the-main-text>
            <app-tabs class="mt" :tabList="tabsList"></app-tabs>

            <component
                v-for="(item, idx) in firstScreen"
                :key="idx"
                :is="item.component"
                :propsData="item.content"
            >
            </component>
        </div>
        <div class="container infoZone" v-if="constructorData">
            <anker-list
                v-if="ankerList"
                :ankerList="ankerList"
                :title="$t('serviceAnkerListTitle')"
                @click="onAnkerClick"
            ></anker-list>
            <div class="contentZone" ref="contentZone">
                <components
                    v-for="(item, idx) in constructorFiltered"
                    :is="item.component"
                    :key="idx"
                    :propsData="item.content"
                    @ankerListCreated="setAnkerList"
                ></components>
            </div>
        </div>
        <template v-if="widgetList">
            <component
                v-for="(item, idx) in widgetList"
                :key="idx"
                :is="item.component"
                :propsData="item.content"
            >
            </component>
        </template>
        <!-- <app-form></app-form> -->
        <app-see-also :morfTitle="countryInfo.morf" :tabList="tabsList"></app-see-also>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppTabs from "../components/common/app-tabs.vue";
import TheMainText from "../components/common/the-main-text.vue";
import AppForm from "../components/country/app-form.vue";
import accordionMixin from "@/textAccordion";
import AppImgPreview from "../components/country/app-imgPreview.vue";
import AppSeeAlso from "../components/country/app-see-also.vue";
import AnkerList from "../components/navigation/anker-list.vue";
import AppListing from "../components/text-components/app-listing.vue";
import AppSimpleText from "../components/text-components/app-simple-text.vue";
import RequestForm from '../components/common/RequestForm.vue';
export default {
    components: {
        AppTabs,
        AppSeeAlso,
        AppListing,
        "simple-text": AppSimpleText,
        AppForm,
        "image-and-text": AppImgPreview,
        TheMainText,
        AnkerList,
        "form1": RequestForm,
    },
    mixins: [accordionMixin],
    data() {
        return {
            countryInfo: null,
            tabsList: null,
            constructorData: null,
            widgetList: null,
            ankerList: null,
            currentH2Idx: 1,
        };
    },
    methods: {
        ...mapActions(["lockUi", "unlockUi"]),
        setAnkerList(ankerList) {
            this.ankerList = ankerList;
        },
    },
    computed: {
        ...mapGetters(["isUiLocked"]),
        constructorFiltered() {
            const temp = [];
            this.constructorData.forEach((el) => {
                if (el.top != 1) {
                    temp.push(el);
                }
            });
            return temp;
        },
        firstScreen() {
            const temp = [];
            this.constructorData.forEach((el) => {
                if (el.top == 1) {
                    temp.push(el);
                }
            });
            return temp;
        },
    },
    async created() {
        this.lockUi();
        const res = await this.axios.post("/api/info/get-by-slug", {
            lang: this.currentLang,
            slug: this.$route.params.infoPageSlug,
        });
        this.tabsList = res.data.data.tabs;
        this.countryInfo = res.data.data.translate;
        this.constructorData = res.data.data.constructor;
        this.widgetList = res.data.data.widgets;
        document.title = res.data.data.translate.meta_title || res.data.data.translate.name || "Dream Travel"
        setTimeout(() => {
            this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
        }, 500);
        this.unlockUi();
    },
};
</script>

<style lang="sass" scoped>
.darkBg
    padding-top: 30px
.descr
    font-weight: 300
    font-size: 17px
    line-height: 150%
    text-align: center
    color: #FFFFFF
    max-width: 586px
    margin: 0 auto
    margin-bottom: 70px
.textContainer
    text-align: center
    padding-top: 70px
.tourList
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 28px
</style>
