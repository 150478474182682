<template>
    <section class="formWrapper">
        <div class="container">
            <h2 class="sectionTitle bef">Календар відпочинку</h2>
            <p class="smallText">
                Обирай для себе потрібну подорож, тисни на типи відпочинку, та
                обирай країни для незабутньої подорожі!
            </p>
        </div>
        <div
            class="form"
            :style="`background-image: url(${require('@/assets/image/airplane2.png')})`"
        >
            <form>123</form>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.formWrapper
    text-align: center
    // @include sectionPadding
    // @media (max-width: 830px)
    //     padding: 100px 0px
.form
    // @include sectionPadding
    // @media (max-width: 830px)
    //     padding: 100px 0px
</style>
