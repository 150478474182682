<template>
    <div class="contentWrapper listingWrapper">
        <h2>Куди завітати</h2>
        <ul>
            <li v-for="i in 5" :key="i">
                <b>Міста-космополіти</b> наповнені знаковими пам'ятками і
                величезними можливостями для шопінгу
            </li>
        </ul>
        <ol>
            <li v-for="i in 5" :key="i">
                <b>Міста-космополіти</b> наповнені знаковими пам'ятками і
                величезними можливостями для шопінгу
            </li>
        </ol>
    </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>

</style>
