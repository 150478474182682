<template>
  <Flickity v-if="tabList" class="tabs" :options="flickityOptions">
    <template v-for="(item, idx) in tabList">
      <router-link
        :key="idx"
        v-if="item.type === 'hotels'"
        :to="{
          name: 'ContryHotels',
          params: {
            countryName: $route.params.countryName,
            locale: currentLangForLink
          }
        }"
        >{{ item.tab_name || item.name }}</router-link
      >
      <router-link
        :key="idx"
        v-else-if="item.type === 'tours'"
        :to="{
          name: 'CountryTours',
          params: {
            countryName: $route.params.countryName,
            locale: currentLangForLink
          }
        }"
        >{{ item.tab_name || item.name }}</router-link
      >
      <router-link
        :key="idx"
        v-else-if="item.type === 'info'"
        :to="{
          name: 'CountryInfos',
          params: {
            countryName: $route.params.countryName,
            infoPageSlug: item.slug,
            locale: currentLangForLink
          }
        }"
        >{{ item.tab_name || item.name }}</router-link
      >
      <template v-else-if="item.type == 'newsCategory'">
        <router-link
          :key="idx"
          v-if="$route.params.slug != item.slug"
          :to="{
            name: 'BlogCategory',
            params: {
              slug: item.slug,
              locale: currentLangForLink
            }
          }"
        >
          {{ item.tab_name || item.name }}
        </router-link>
        <router-link
          :key="idx"
          v-else
          class="active"
          :to="{
            name: 'Blog',
            params: {
              locale: currentLangForLink
            }
          }"
        >
          {{ item.tab_name || item.name }}
        </router-link>
      </template>
      <router-link
          :key="idx"
          v-else-if="item.type == 'self'"
          :to="{
            name: 'Blog',
            params: {
              locale: currentLangForLink
            }
          }"
        >
          {{ item.tab_name || item.name }}
        </router-link>
      <router-link
        :key="idx"
        v-else
        :to="{
          name: 'Contry',
          params: {
            countryName: $route.params.countryName,
            locale: currentLangForLink
          }
        }"
      >
        {{ item.tab_name || item.name }}
      </router-link>
    </template>
  </Flickity>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  components: {
    Flickity
  },
  props: {
    tabList: {
      type: [Array, Object],
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      flickityOptions: {
        // prevNextButtons: false,
        pageDots: false,
        contain: true,
        cellAlign: "center",
        groupCells: true
      }
    };
  }
};
</script>

<style lang="sass">
.country .tabs
    margin-top: 45px
.tabs
  .flickity-button
    padding: 0
    background-color: #2a4651
    transform: unset
    border-radius: 0
    width: 40px
    height: unset
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    svg
      height: 12px
    &:disabled
      opacity: 0
    .flickity-button-icon
      width: 15px
      left: unset
      right: unset
      top: unset
      bottom: unset
      position: static
  .previous
    left: 0
    bottom: 0
    top: 0
  .next
    right: 0
    bottom: 0
    top: 0
  a
    padding: 12px 20px
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    transition: .3s
    &:hover
        background-color: #2A4651
        color: #fff
    &.router-link-exact-active.router-link-active, &.active
      color: #17171D
      font-weight: 600
      background-color: #4AA7BC
</style>
